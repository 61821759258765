<script>
  import Loader from "../components/Loader.svelte";
  import { fetchJSON } from "../utils";

  let isLoading = false;
  let providedID = "";
  let finished = false;
  let errorMessage = null;

  async function sendEmail(data) {
    isLoading = true;

    try {
      const response = await fetchJSON("/api/v1/stripe/portal-access-email", {
        method: "POST",
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error("Response not OK");
    } finally {
      isLoading = false;
    }
  }

  async function formSubmit() {
    let data;
    const orderID = Number(providedID);
    if (isNaN(orderID)) {
      data = { stripe_subscription_id: providedID };
    } else {
      data = { order_id: orderID };
    }

    try {
      await sendEmail(data);
      finished = true;
    } catch (error) {
      errorMessage = `
        Sorry, we couldn't match the details you supplied. Please provide a
        Order Number (without any letters eg. ‘W' prefix)
      `;
    }
  }
</script>

<svelte:head>
  <title>OBOD Member Course Billing Management Area</title>
</svelte:head>

<h1>OBOD Member Course Billing Management Area</h1>

<main>
  {#if finished}
    <p>Thank you. An email has been sent to your registered email address.</p>

    <p>
      If you do not receive the email, please wait a few minutes &amp; check
      your junk folder. But, if you still are having difficulties, please send
      us an email to:
      <a href="mailto:admin@druidry.org">admin@druidry.org</a> with your name &amp;
      address.
    </p>
  {:else}
    <p>
      For your security, please provide your ‘Order Number’ to receive an email
      containing a link to access the billing management area where you will be
      able to manage &amp; update your payment method / card details, and view
      your billing history statement.
    </p>

    <p>
      Your ‘Order Number’ is a 6 digit number provided in your confirmation
      email, and also in the welcome email subject line. If you do not have this
      number, please send us an email to: <a href="mailto:admin@druidry.org"
        >admin@druidry.org</a
      > with your name &amp; address.
    </p>

    <form on:submit|preventDefault={formSubmit}>
      <div>
        <label for="details-last-name">Order Number*</label>
        <input
          name="id"
          type="text"
          required
          bind:value={providedID}
          title="Please enter your order number"
        />
        {#if errorMessage}
          <div class="error-message">{errorMessage}</div>
        {/if}
      </div>

      <div class="submit">
        <input class="btn btn--primary" type="submit" value="Submit" />
      </div>
    </form>
  {/if}
</main>

<Loader visible={isLoading}>
  <div class="processing">Please wait ...</div>
</Loader>

<style>
  p {
    margin-bottom: 0.5em;
  }

  form {
    margin-top: 1em;
  }

  .submit {
    margin-top: 0.25em;
  }

  .submit input {
    width: 100%;
  }
</style>
